.wyswiyg-content {
	@apply  inline-block;

	* {
		@apply my-4;

		&:first-child {
			@apply mt-0;
		}
	}
	
	p {
		@apply text-base leading-6 lg:text-lg text-gray-dark;
	}

	h1, h2, h3, h4, h5, h6 {
		@apply mb-6 mt-8;
	}

	> ul {
		@apply list-disc;
	}
		
	> ol {
		@apply list-decimal;

		&::marker {
			@apply font-bold;
		}
	}

	li {
		@apply ml-6 pl-4;

		&::marker {
			@apply text-green;
		}
	}

	blockquote {
		p {
			@apply text-green text-xl leading-7 lg:text-2xl lg:leading-9
		}
	}

	a {
		@apply text-green hover:text-green-dark decoration-2 decoration-green transition-colors hover:decoration-green-dark
	}
}