.self-subscribe {
	@apply w-full h-screen;

	&__progress {
		@apply sticky top-0 z-30;
	}

	&__container {
		@apply h-full;
	}

	&__child {
		@apply col-span-1 md:col-span-6;
	}

	&__child--media {
		@apply hidden bg-gray md:flex;
	}
}
