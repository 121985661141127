// React Select override
form {
	.react-select__control {
		@apply rounded-none min-h-0 border-solid shadow-none w-full px-4 md:px-6 py-4 mt-1 text-base leading-6 border border-transparent focus:border-green focus:shadow-soft bg-light;

		&--menu-is-open {
			@apply border-green border shadow-soft;
		}

		&--is-focused {
			@apply border-green border shadow-soft;
		}
	}

	.react-select__indicator {
		@apply p-0;
	}

	.react-select__indicator-separator {
		@apply hidden p-0;
	}

	.react-select__input-container,
	.react-select__value-container,
	.react-select__placeholder {
		@apply p-0 m-0;
	}

	.react-select__menu {
		@apply my-0 z-20;
	}

	.react-select__menu-list {
		@apply py-0;
	}

	.react-select__option {
		@apply bg-green-dark text-white py-4 px-6;

		&--is-selected {
			@apply green-gradient;
		}
	}
}
