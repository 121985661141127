.form-input {
	&__label {
		@apply text-green-dark leading-6 font-bold text-xs;
	}

	&__control {
		@apply w-full relative;
	}

	&__input {
		@apply block w-full px-4 py-4 mt-1 text-lg leading-6 border border-transparent focus:border-green focus:shadow-soft bg-light;

		&:disabled {
			@apply opacity-40;
		}

		&--error {
			@apply border-error;
		}
	}

	&__toggle {
		@apply w-6 h-6 absolute top-1/2 right-2 -translate-y-1/2;
	}

	&__toggle-icon {
		@apply w-6 h-6;
	}

	&__error {
		@apply text-sm text-error;
	}
}
