.skeleton {
	@apply h-full rounded-lg;
	animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(120, 3%, 94%);
	}
	100% {
		background-color: hsl(0, 0%, 97%);
	}
}
