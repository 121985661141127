.whr-items {
	display: grid !important;
	
	@apply grid-cols-1 gap-6 mt-6 sm:mt-4 sm:grid-cols-2;
}

.whr-item {
	@apply mt-4 sm:mt-0;
}

.whr-toggle,
.whr-group {
	@apply lg:text-4xl mt-6 lg:mt-8 pointer-events-none block pb-5 mb-5 border-b lg:mb-6 lg:pb-6 border-gray;
}

.whr-title {
	@apply lg:text-2xl;

	a {
		@apply link link--static-underline;
	}
}

.whr-info {
	@apply mt-4 md:mt-6;

	span {
		@apply text-green font-bold;
	}
}
