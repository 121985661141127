.dropdown-checklist {
	@apply bg-green-light rounded-lg;

	&__trigger {
		@apply px-4 py-4 flex w-full items-center text-sm leading-4 relative;
	}

	&__trigger-label {
		@apply w-full text-green-dark font-medium flex-grow text-left;
	}

	&__trigger-icon {
		@apply flex relative;
	}

	&__trigger-icon-label {
		@apply pr-4;
	}

	&__trigger-icon-svg {
		@apply rotate-90 text-green absolute top-1/2 right-0 -translate-y-1/2;

		&--active {
			@apply -rotate-90;
		}
	}

	&__list {
		@apply px-4 py-4 pt-0 md:grid md:grid-cols-2;
	}

	&__list-label {
		@apply text-gray-dark text-sm leading-4 ml-2 mt-2 first:mt-0;
	}
}
