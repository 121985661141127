.breadcrumbs {
	&__container {
		@apply flex gap-x-2 items-center text-xs lg:text-sm;
	}

	&__activeItem {
		@apply text-white/100;
	}

	&__inactiveItem {
		@apply text-white/50 last:text-white;

		&:last-child {
			@apply overflow-hidden;

			.breadcrumbs__label {
				@apply truncate;
			}
		}
	}

	&__item {
		@apply flex items-center;

		&:last-child {
		}
	}

	&__icon {
		@apply mr-2 pointer-events-none text-white/50;
	}

	&__label {
	}
}
