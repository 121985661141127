.alert-bar {
	@apply flex bg-error text-white fixed top-0 left-0 w-full py-4 px-6 z-40 text-center items-center;

	&--success {
		@apply bg-green;
	}

	&__text {
		@apply flex-grow text-center;
	}
}
