.form-radio {
	@apply relative flex w-full flex-grow;

	input {
		@apply absolute opacity-0;

		&:checked + label {
			@apply text-white bg-green-dark;
		}
	}

	label {
		@apply text-green-dark cursor-pointer text-center m-1 rounded-md py-4 text-sm flex-grow w-full font-medium;
	}
}

.form-radio--button {
	@apply text-gray-dark text-opacity-80 cursor-pointer text-center m-1 rounded-md py-4 text-sm flex-grow w-full justify-center bg-gray-light font-medium;
}
