.progress-bar {
	@apply w-full;

	&__container {
		@apply bg-gray-light h-2;
	}

	&__bar {
		@apply green-gradient h-full transition-width duration-150 ease-in;
	}
}
