.form-checkbox {
	@apply border border-gray-light relative rounded-lg flex items-center px-3 py-3 text-sm leading-5 mt-1 relative;

	&__info {
		@apply flex-grow flex items-center;
	}

	&__label {
		@apply text-gray-dark pl-8;

		&:after {
			content: '';
			@apply w-5 h-5 bg-gray-light absolute left-3 rounded-sm border border-gray border-opacity-50;
		}

		&:before {
			content: '';
			@apply w-5 h-5 absolute left-3 z-10 bg-no-repeat bg-center;
		}
	}

	&__input {
		@apply relative hidden;

		&:checked + label:after {
			@apply bg-green green-gradient;
		}

		&:checked + label:before {
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgN0wzLjg0NjE1IDEwLjVMMTAgMS41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
		}
	}

	&__name {
	}

	&__included {
		@apply text-green-dark py-1 px-2 bg-green-light rounded-sm;
	}

	&__price {
	}

	&__billing {
		@apply text-xs;
	}

	&__helper {
		@apply items-center w-auto flex h-4 ml-2;
		svg {
			width: pxToRem(16);
			height: pxToRem(16);
		}

		button span {
			@apply flex;
		}
	}

	&__tooltip {
		@apply absolute bottom-full -translate-y-2 bg-white w-full left-0 p-5 rounded-lg drop-shadow-md;
	}
}
