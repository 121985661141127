.form-step {
	@apply w-full;

	&--training {
		@apply h-full flex flex-col;
	}

	&__icon-container {
		@apply flex justify-center mb-6;
	}

	&__icon {
		@apply w-16 h-16;

		&--complete {
			@apply green-gradient rounded-full text-white flex items-center justify-center md:w-20 md:h-20;

			svg {
				@apply w-4 h-4 md:w-6 md:h-6;
			}
		}
	}

	&__heading {
		@apply text-center;
	}

	&__iframe {
		@apply w-full h-[448px] md:h-[530px] lg:h-[660px] md:mt-10;
	}

	&__description {
		@apply text-center text-sm text-gray-dark mt-2 leading-6 md:mt-5 md:text-base;
	}

	&__training-modal-trigger {
		@apply mt-8;
	}

	&__dropdown {
		@apply mt-6 md:mt-9;
	}

	&__action {
		@apply flex items-center flex-col;

		.cta + .cta {
			@apply mt-2;
		}
	}

	&__form {
		@apply w-full flex-grow pb-6;
	}

	&__form-row {
		@apply flex-col w-full flex md:flex-row;
	}

	&__form-col {
		@apply w-full flex flex-col mt-7 md:mt-7;

		+ .form-step__form-col {
			@apply md:ml-4;
		}
	}

	&__form-col--submit {
		@apply flex-col md:mt-10;
	}

	&__form-row--submit {
		@apply w-full flex-col md:flex md:justify-center md:items-center mb-4 md:mb-10;

		.cta {
			@apply md:w-80;
		}
	}

	&__form-label {
		@apply flex items-center justify-between text-green-dark text-base leading-6 font-bold;
	}

	&__form-label-help {
		@apply text-sm flex items-center;
	}

	&__form-label-help-label {
		@apply underline;
	}

	&__form-label-help-icon {
		@apply items-center w-auto flex h-4 ml-1;
		svg {
			width: pxToRem(16);
			height: pxToRem(16);
		}

		span span {
			@apply flex;
		}
	}

	&__form-input {
		@apply flex w-full;
	}

	&__form-input--text {
		@apply flex-col;
	}

	&__form-input--radio {
		@apply border border-gray-light rounded-lg;
	}

	&__form-input--checkbox {
		@apply flex-col;
	}

	&__summary {
		@apply mt-4 md:h-[120px] md:mt-9;
	}
}
