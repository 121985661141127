.modal {
	@apply w-full z-20 mt-12 md:mt-20 mb-10 max-w-[530px] mx-4 md:mx-0 transition-all bg-white pointer-events-auto lg:relative;

	&--video_modal {
		@apply md:max-w-[1024px] md:w-full;
	}

	&--subscribe_modal {
		.modal__children {
			@apply p-5 md:p-4;
		}

		&__header {
			@apply bg-white border-b border-b-gray-light;
		}
	}

	&__header {
		@apply flex items-center justify-between p-5 lg:p-4;
	}

	&__header-label-container {
	}

	&__header-label {
		@apply text-lg font-bold leading-6 text-green-dark;
	}

	&__header-close {
		@apply flex items-center w-6 h-6 text-black;
	}
}
