.self-subscribe-media {
	@apply p-10 w-full top-0 sticky mt-10;

	&--image {
		@apply mt-0;
	}

	&--image-graphic {
		@apply mt-0 pt-0;

		.self-subscribe-media__image {
			// @apply relative max-w-[595px] h-[358px] rounded-md overflow-hidden mx-auto bg-error;
			@apply relative aspect-w-16 aspect-h-9 rounded-md overflow-hidden mx-auto;
		}
	}

	&__content {
		@apply max-w-[660px] mx-auto mt-10;
	}

	&__graphic {
		@apply py-30 relative;
	}

	&__image {
		@apply px-5 text-center relative;
	}

	&__heading {
		@apply text-green-dark text-2xl leading-9 font-bold text-center block w-full;
	}

	&__description {
		@apply text-gray-dark text-center text-base leading-6 block w-full mt-4;
	}

	&__pagination {
	}

	&__bullets {
		@apply flex justify-center;

		.swiper-pagination-bullet {
			@apply rounded-lg transition-width duration-150 ease-in-out;
		}

		.swiper-pagination-bullet-active {
			@apply green-gradient w-12 transition-width;
		}
	}
}
