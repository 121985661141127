.cost-summary {
	@apply bg-green rounded-br-3xl relative text-white py-3 h-full flex flex-col items-center justify-center;

	&__summary-saving {
		@apply absolute bg-orange top-0 right-0 -translate-y-1/2 text-sm rounded-md px-2 py-1;
	}

	&__summary-cost {
		@apply flex justify-center items-baseline;
	}

	&__summary-cost-currency {
		@apply flex flex-col items-start mr-1;
	}

	&__summary-cost-amount {
		@apply text-7xl font-bold relative;

		// &:before {
		// 	content: '$';
		// 	right: calc(100% + 8px);

		// 	@apply absolute top-1 right-full text-xl;
		// }
	}

	&__summary-bill-rate {
		@apply text-center text-sm;
	}
}
