.form-step-layout {
	height: calc(100% - 8px);

	@apply grid grid-cols-1 md:grid-cols-12 h-full;

	&__child {
		@apply col-span-1 md:col-span-6 md:relative;
	}

	&__child--media {
		@apply hidden bg-gray-light md:block overflow-hidden;
	}

	&__child--form {
		@apply px-5;
	}

	&__container {
		@apply md:max-w-[528px] w-full mx-auto flex-grow flex flex-col h-full;
	}

	&__helpers {
		@apply flex justify-between w-full py-4 md:pb-5 md:absolute md:left-0 md:top-0 md:px-4;
	}

	&__logo {
		@apply flex justify-center pt-6 md:pt-14;
	}

	&__heading {
		@apply text-center w-full;
	}

	&__form {
		@apply mt-7 flex-grow md:mt-12;
	}

	&__help {
		@apply text-sm;

		a {
			@apply text-green duration-150 transition-colors ease-in-out underline hover:text-green-dark;
		}
	}

	&__back {
	}

	&__back-trigger {
		@apply flex items-center text-sm left-4;
	}

	&__back-trigger-icon {
		@apply rotate-180 text-green mr-2;
	}
}
