@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600&display=swap');
@import 'utils/swiper';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'helpers/functions';
@import 'base';
@import 'wysiwyg';
@import 'modal';
@import 'workable';
@import 'breadcrumbs';
@import './utils/react-select.scss';

@import 'components/self-subscribe/alert';
@import 'components/self-subscribe/cost-summary';
@import 'components/self-subscribe/dropdown-checklist';
@import 'components/self-subscribe/form-checkbox';
@import 'components/self-subscribe/form-input';
@import 'components/self-subscribe/form-radio';
@import 'components/self-subscribe/form-step';
@import 'components/self-subscribe/form-step-layout';
@import 'components/self-subscribe/self-subscribe';
@import 'components/self-subscribe/self-subscribe-media';
@import 'components/self-subscribe/skeleton';
@import 'components/self-subscribe/progress-bar';

* {
	-webkit-tap-highlight-color: transparent;
}

html {
	-webkit-font-smoothing: antialiased;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

input {
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}
}

@layer components {
	.cta {
		@apply flex w-full md:w-auto relative overflow-hidden items-center transition-colors duration-200 ease-in-out text-center;

		.cta-label {
			@apply flex-grow min-w-fit text-white z-10 text-inherit;
		}

		.cta-icon {
			@apply bg-opacity-20 bg-white z-10 text-white rounded-full ml-4 mr-1 mt-1 mb-1 flex justify-center items-center shrink-0;
		}

		.cta-overlay {
			@apply bg-black bg-opacity-10 z-0 absolute-fill transition-opacity duration-200 ease-in-out opacity-0;
		}

		&:disabled {
		}

		&--large {
			@apply text-lg min-h-[58px];

			.cta-icon {
				@apply w-10 h-10 mr-2;
			}
		}

		&--medium {
			@apply text-base min-h-[50px];

			.cta-icon {
				@apply w-10 h-10;
			}
		}

		&--small {
			@apply text-sm min-h-[46px];

			.cta-icon {
				@apply w-9 h-9;
			}

			.cta-label {
				@apply text-sm;
			}
		}

		&--primary,
		&--secondary,
		&--tertiary,
		&--alt,
		&--white {
			@apply rounded-[40px] border px-8;
		}

		&--primary {
			@apply bg-orange hover:bg-orange-dark text-white dark:bg-white;

			.cta-label {
				@apply dark:text-green-dark z-10 text-inherit;
			}

			&:disabled {
				@apply opacity-30;
			}
		}

		&--secondary {
			@apply text-white;
			background: linear-gradient(90deg, #22b573 0%, #1bd0bc 100%);

			&:hover {
				background: linear-gradient(90deg, #22b573 0%, #1bd0bc 100%);

				@apply transition-colors duration-200 ease-in-out;

				.cta-overlay {
					@apply opacity-100;
				}
			}
		}

		&--tertiary {
			@apply bg-transparent border-green text-green hover:border-green hover:bg-green hover:bg-opacity-10 dark:bg-transparent dark:text-white dark:border-white dark:hover:bg-white dark:hover:bg-opacity-10;

			.cta-icon {
				@apply bg-green bg-opacity-10 text-green dark:text-white dark:bg-white dark:bg-opacity-10;
			}
		}

		// For google maps country triggering
		&--alt {
			@apply bg-green-light text-green lg:min-w-[223px] lg:min-h-[52px];

			.cta-label {
				@apply text-sm font-bold lg:text-base;
			}

			&:disabled {
				@extend .cta--secondary;
				@apply lg:px-16;
			}
		}

		&--white {
			@apply bg-white hover:bg-gray-light text-green-dark dark:bg-gray;

			.cta-label {
				@apply dark:text-white;
			}

			.cta-icon {
				@apply bg-green-dark bg-opacity-[.15] text-green-dark dark:text-white dark:bg-white dark:bg-opacity-10;
			}
		}

		&--link,
		&--nav {
			@apply text-green-dark dark:text-white after:h-0.5 after:origin-left after:bottom-0 after:absolute hover:after:scale-x-100 after:transition-all;

			.cta-icon {
				@apply text-green dark:text-green mx-0 bg-transparent transition-colors justify-end w-6;
			}
		}

		&--link {
			@apply inline-flex w-auto after:w-16 hover:after:w-full font-medium after:bg-green after:hover:bg-orange;

			&:hover .cta-icon {
				@apply text-orange;
			}
		}

		&--nav {
			@apply text-sm lg:text-base flex w-full text-left lg:inline-flex lg:w-auto after:w-full after:scale-x-0 hover:after:scale-x-100 font-bold after:bg-gradient-to-r after:from-green to-[#1BD0BC];
		}

		&--icon {
			@apply pr-0;
		}

		&--chip {
			@apply min-h-[32px] px-4;

			.cta-label {
				@apply text-sm mr-0 font-medium;
			}
		}

		&--form-nav {
			@apply mt-4 text-sm lg:text-base flex w-full text-center lg:inline-flex lg:w-auto font-bold text-green-dark underline;
		}
	}

	.link {
		@apply relative inline-block font-bold text-green-dark;

		&--static-underline {
			@apply after:w-full after:h-0.5 after:scale-x-100 after:bg-gradient-to-r after:from-green to-[#1BD0BC] after:origin-left after:left-0 after:bottom-[-2px] after:absolute after:transition-all;
		}
	}

	.filter-pill {
		@apply inline-block w-auto px-4 py-2 lg:py-[11px] lg:text-sm text-xs transition-colors bg-white rounded-full first:ml-4 hover:bg-green hover:bg-opacity-30 sm:first:ml-0;

		&--active {
			@apply green-gradient text-white;
		}
	}

	.absolute-fill {
		@apply absolute w-full h-full top-0 left-0 right-0 bottom-0;
	}

	.absolute-center {
		@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
	}

	.green-gradient {
		@apply bg-gradient-to-r from-green to-[#1BD0BC];
	}

	.section-grey-gradient {
		background: linear-gradient(
			180deg,
			rgba(247, 247, 247, 0) 0%,
			#f7f7f7 100%
		);
		background-position: bottom right;
		background-repeat: no-repeat;

		@apply bg-contain;
	}

	.resource_overview-resources {
		.group {
			&:first-child {
				.resource_card-container {
					@apply lg:pt-0;
				}
			}
		}
	}

	.hamburger {
		@apply p-2 cursor-pointer border border-green rounded-full;

		transition-property: opacity, filter;
		transition-duration: 0.15s;
		transition-timing-function: linear;

		&.is-active {
			.hamburger-inner {
				@apply rotate-45;
				transition-delay: 0.12s;
				transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

				&::before {
					@apply top-0 opacity-0;
					transition: top 0.075s ease, opacity 0.075s 0.12s ease;
				}

				&::after {
					@apply bottom-0 -rotate-90;
					transition: bottom 0.075s ease,
						transform 0.075s 0.12s
							cubic-bezier(0.215, 0.61, 0.355, 1);
				}
			}
		}
	}

	.hamburger-box {
		@apply relative h-4 w-4;
	}

	.hamburger-inner {
		@apply block top-1/2 -mt-px;
		transition-duration: 0.075s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

		&,
		&::before,
		&::after {
			@apply absolute block w-4 h-0.5 bg-green-dark rounded;
		}

		&::before,
		&::after {
			content: '';
		}
		&::before {
			@apply top-1.5;
			transition: top 0.075s 0.12s ease, opacity 0.075s ease;
		}

		&::after {
			@apply bottom-1.5;
			transition: bottom 0.075s 0.12s ease,
				transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}
	}

	#apexchat_tab_invitation_wrapper {
		@apply hidden #{!important};
	}
}
